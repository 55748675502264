.swiper {
  &-button {
    &-disabled {
      opacity: 0.5;
    }
    &-next,
    &-prev {
      display: inline-block;
      font-size: 1.75rem;
      bottom: 2rem;
      z-index: 10;
      position: absolute;
      transition: all 0.15s ease-out;
      @include media-breakpoint-up(lg) {
        padding: 1rem;
        font-size: 1.2rem;
        bottom: 50%;
      }
      &:not(.swiper-button-disabled):hover {
        cursor: pointer;
        transform: scale(1.15);
      }
    }
    &-prev {
      right: 3rem;
      @include media-breakpoint-up(lg) {
        left: percentage(1/12);
        right: auto;
      }
    }
    &-next {
      right: 0.5rem;
      @include media-breakpoint-up(lg) {
        right: percentage(1/12);
      }
    }
  }
  &-pagination {
    line-height: 0;
    margin-top: 1.5rem;
    &-bullet {
      display: inline-block;
      width: 1.5rem;
      height: 3px;
      background-color: $color-greyscale-10;
      transition: background-color 0.15s ease-out;
      & + & {
        margin-left: 0.25rem;
      }
      &-active {
        background-color: $color-accent-yellow;
      }
    }
  }
}
