/* NAVIGATION */
$navigation-height: 79px;
.nav {
  $component: &;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  height: $navigation-height;
  background-color: $color-bg-dark;
  border-bottom: 1px solid transparentize($color-greyscale-00, 0.85);
  will-change: background-color, transform;
  &--scrolled {
    position: fixed;
    background-color: $color-greyscale-00;
    border-bottom: 1px solid $color-greyscale-01;
    transform: translateY(-100%);
  }
  &--scrolled-down {
    transition: transform 0.15s ease-out;
    transform: translateY(0);
  }
  &--scrolled-up {
    transition: transform 0.15s ease-out;
    transform: translateY(0);
  }
  &__container {
    $max-width: map-get($container-max-widths, 'xl') + 4*16;
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    @include media-breakpoint-up(lg) {
      padding: 0 2rem;
    }
  }
  &__logo {
    .letter {
      #{$component}--scrolled & {
        fill: $color-greyscale-10 !important;
      }
    }
  }
  &__toggle {
    font-size: 1.5rem;
    color: $color-greyscale-00;
    #{$component}--scrolled & {
      color: $color-greyscale-10;
    }
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  &__list-item {
    display: inline-block;
  }
  &__link {
    font-weight: 600;
    color: $color-greyscale-07;
    transition: color 0.15s ease-out;
    will-change: color;
    display: block;
    padding: 0.75rem 2rem;
    @include media-breakpoint-up(lg) {
      padding: 0.75rem 0;
    }
    &--active {
      font-weight: 700;
    }
    &--active, &:focus, &:hover {
      color: $color-greyscale-00;
      text-decoration: none;
    }
    #{$component}--scrolled & {
      color: transparentize($color-greyscale-10, 0.45);
      &--active, &:focus, &:hover {
        color: $color-greyscale-10;
      }
    }
  }
  &__cta {
    display: none;
    .btn {
      display: flex;
      > i {
        margin-left: 0.5rem;
      }
    }
    @include media-breakpoint-up(lg) {
      #{$component}--scrolled & {
        display: block;
      }
    }
  }
  &__main {
    position: absolute;
    display: none;
    z-index: 10;
    left: 0;
    width: 100%;
    top: calc(100% + 1px);
    background-color: $color-bg-dark;
    will-change: background-color;
    padding: 0.75rem 0;
    border-bottom: 1px solid transparentize($color-greyscale-00, 0.85);
    &--open {
      display: block;
    }
    #{$component}--scrolled & {
      background-color: $color-greyscale-00;
      border-bottom: 1px solid $color-greyscale-01;
    }
    @include media-breakpoint-up(lg) {
      border: none;
      padding: 0;
      background-color: transparent !important;
      display: block;
      position: relative;
      top: 0;
      #{$component}--scrolled & {
        border-bottom: none;
      }
    }
  }
  &__main &__list-item + &__list-item {
    @include media-breakpoint-up(lg) {
      margin-left: 1rem;
    }
    @include media-breakpoint-up(xl) {
      margin-left: 2rem;
    }
  }
  &__main &__list-item {
    display: block;
    @include media-breakpoint-up(lg) {
      display: inline-block;
    }
  }
  &__social {
    margin-left: auto;
    @include media-breakpoint-up(lg) {
      margin-left: 0;
    }
  }
  &__social &__list-item {
    a {
      color: $color-greyscale-00;
      font-size: 1.5rem;
      transition: color 0.15s ease-out;
    }
    #{$component}--scrolled & {
      a {
        color: $color-greyscale-10;
      }
    }
  }
  &__social &__list-item + &__list-item {
    margin-left: 0.75rem;
    @include media-breakpoint-up(sm) {
      margin-left: 1.5rem;
    }
  }
  &__image {
    width: 140px;
    > img {
      width: 100%;
    }
  }
  &__divider {
    display: block;
    width: 1px;
    height: 3rem;
    margin: 1rem 1.5rem;
    background-color: transparentize($color-greyscale-00, 0.85);
    #{$component}--scrolled & {
      background-color: $color-greyscale-01;
    }
    @include media-breakpoint-up(xl) {
      margin: 1rem 2.5rem;
    }
    &--2 {
      margin-left: auto;
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
      }
      #{$component}--scrolled & {
        display: none;
      }
    }
    &--3 {
      @include media-breakpoint-up(lg) {
        display: none;
      }
      #{$component}--scrolled & {
        display: block;
      }
    }
  }
}
