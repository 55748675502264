@import "variables";
@import "~normalize.css";
@import "mixins";
@import 'typography';
@import "bootstrap";
@import "navigation";
@import "list";
@import "~swiper/swiper";
@import "swiper";
/* GLOBAL */
html  {
  scroll-behavior: smooth;
}
body {
  font-family: Gilroy, 'sans-serif';
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  letter-spacing: 0.2px;
}
h2 {
  font-size: 40/16 + rem
}
img {
  max-width: 100%;
  height: auto;
}
a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
/* BUTTONS */
.btn {
  padding: 0.75rem 1.25rem;
  @include media-breakpoint-up(lg) {
    padding: 14/16 +rem 2rem;
  }
  border-radius: 0;
  border-width: 3px;
  font-weight: 700;
  line-height: 20/16 +rem;
}
.btn-outline-primary {
  color:#fff;
}

/* SECTION HOME*/

.section-home {
  padding-top: $navigation-height;
  color: $color-greyscale-005;
  background: url('/public/img/dashed-line-1.svg') 10% 40% no-repeat,
          url('/public/img/dashed-line-2.svg') 40% 60% no-repeat,
          url('/public/img/dashed-line-3.svg') 100% 88% no-repeat,
          $color-bg-dark;
  &__image {
    margin: 0 auto;
    display: none;
    @include media-breakpoint-up(lg) {
      position: relative;
      display: block;
      top: 48px;
    }
  }
  &__title {
    font-size: 2rem;
    color: $color-greyscale-00;
    font-weight: bold;
    margin: 0;
    padding: 1rem 0;
    line-height: 42/16 +rem;
    @include media-breakpoint-up(lg) {
      line-height: 54/16 +rem;
      font-size: 3rem;
    }
    span {
      color: $color-accent-yellow;
      display: inline;
      position: relative;
      z-index: 10;
      line-height: 1.4rem;
      @include media-breakpoint-up(lg) {
        line-height: 2rem;
      }
      @include text-underline(transparentize($color-accent-yellow, 0.8))
    }
  }
  &__text {
    margin:0;
    padding: 1rem 0;
  }
  &__buttons {
    display: flex;
    padding: 1.5rem 0 0 0;
    font-weight: 700;
    @include media-breakpoint-up(lg) {
      padding: 3.5rem 0 0 0;
    }
  }
  &__cta {
    max-width: 100%;
    white-space: nowrap;
    padding: 0.5rem 0.875rem;
    font-size: 0.925rem;
    margin-bottom: 1.5rem;
    @include media-breakpoint-up(sm) {
      padding: 0.75rem 1.25rem;
      font-size: 1rem;
    }
    @include media-breakpoint-up(lg) {
      width: 194/16 +rem;
    }
    i {
      margin-left: 0.5rem;
    }
  }
  &__cta + &__cta {
    margin-left: 0.5rem;
    @include media-breakpoint-up(md) {
     margin-left: 1rem;
    }
  }
}
/* SECTION ABOUT */

.section-about {
  &--2 {
    padding-bottom: 3rem;
    padding-top: 3rem;
    background-color: $color-bg-light;
    @include media-breakpoint-up(lg) {
      padding-top: 7rem;
      padding-bottom: 7rem;
    }
  }
  &__container {
    &--1 {
      padding-top: 3rem;
      padding-bottom: 120%;
      background: url("/public/img/michal.png") right bottom no-repeat;
      background-size: 80%;
      @include media-breakpoint-up(lg) {
        padding-top: 228/16 +rem;
        padding-bottom: 208/16 +rem;
        background: url("/public/img/michal.png") left bottom no-repeat;
        background-size: percentage(5/12);
      }
    }
    &--2 {
      @include media-breakpoint-up(lg) {
        padding: 2rem 0;
        background: url("/public/img/bg-about-2.jpg");
        background-position: right center;
        background-repeat: no-repeat;
        background-size: auto 100%;
      }
      @include media-breakpoint-up(xl) {
        padding: 2rem 0;
        background: url("/public/img/bg-about-2.jpg"), url("/public/img/bg-about-2-2.jpg");
        background-position: right 210px center, right center;
        background-repeat: no-repeat;
        background-size: auto 100%;
      }
    }
  }
  &__subtitle {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $color-greyscale-03;
    margin-bottom: 0.75rem;
  }
  &__title {
    font-size: 2.35rem;
    margin-bottom: 1.5rem;
    letter-spacing: 0.2px;
    line-height: 1.2;
    &--accent {
      display: inline;
      @include text-underline($color-accent-yellow)
    }
  }
  &__description {
    margin-bottom: 2rem;
    &--big {
      font-size: 18/16 + rem;
      font-weight: bold;
    }
  }
}

/* SECTION ADVANTAGES */

.section-advantages {
  background-color: $color-bg-dark-level-up;
  color: #fff;
  position: relative;
  min-height: calc(100vh - 80px);
  padding: 2rem 0;
  &__element {
    padding: 2rem 1rem;
    width: 100%;
    & + & {
      margin-top: 2rem;
    }
    @include media-breakpoint-up(lg) {
      z-index: 10;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      position: absolute;
      & + & {
        margin-top: 0;
      }
    }
    &:not(&--1) {
      @include media-breakpoint-up(lg) {
        opacity: 0;
      }
    }
    @include media-breakpoint-up(lg) {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 1px;
        background-color: transparentize($color-greyscale-00, 0.85);
        height: 100%;
      }
      padding: 4rem 0;
    }
  }
  &__title-container {
    @include media-breakpoint-up(lg) {
      text-align: right;
    }
  }
  &__description {
    margin-bottom: 2rem;
  }
  &__title {
    font-size: 2.5rem;
    line-height: 1.25;
    white-space: nowrap;
    @include media-breakpoint-up(lg) {
      font-size: 4rem;
    }
    &--accent {
      color: $color-accent-yellow;
      margin-bottom: 0;
    }
  }
}
.section-portfolio {
  background-color: $color-bg-dark;
  color: $color-greyscale-02;
  padding: 3rem 0;
}
.portfolio-item {
  margin-top: 0rem;
  padding: 0 1rem;
  @include media-breakpoint-up(lg) {
    margin-top: 5rem;
    padding: 0;
  }
  & + & {
    margin-top: 8rem;
  }
  &__description {
    margin: 4rem 0;
    font-weight: 600;
    font-size: 20/16 +rem;
    line-height: 1.4;
    letter-spacing: 0.2px;
  }
  &__title {
    $bottom-margin: 3rem;
    padding-bottom: 1.5rem;
    margin-bottom: $bottom-margin;
    border-bottom: 1px solid transparentize($color-greyscale-00, 0.85);
    position: relative;
    &::before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      height: 2px;
      width: percentage(265/600);
      background-color: $color-greyscale-02;
    }
    &--yellow {
      &::before {
        background-color: $color-accent-yellow;
      }
    }
    &--green {
      &::before {
        background-color: $color-accent-green;
      }
    }
    &--red {
      &::before {
        background-color: $color-accent-red;
      }
    }
    &--dark-red {
      &::before {
        background-color: $color-accent-dark-red;
      }
    }
  }
}

.sticky-menu {
  position: sticky;
  top: $navigation-height + 16px;
  list-style: none;
  padding: 1.5rem 0;
  margin: 4.5rem 0 0;
  &-container {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
      border-right: 1px solid transparentize($color-greyscale-00, 0.85);
    }
  }
  &-item {
    position: relative;
    padding-left: 1.75rem;
    font-size: 18/16 +rem;
    line-height: 1;
    font-weight: 600;
    margin-bottom: 1.5rem;
    letter-spacing: 0.2px;
    color:$color-greyscale-07;
    > a {
      color:$color-greyscale-07;
    }
    &::after,
    &::before,
    > a {
      transition: all 0.15s ease-out;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top:0;
      width: 1rem;
      height: 1rem;
      border-radius: 100%;
      border: 2px solid $color-greyscale-07;
    }
    &--active {
      &::before {
        border-color: $color-accent-yellow;
      }
      &::after {
        content: '';
        position: absolute;
        left: 0.375rem;
        top: 0.375rem;
        width: 0.25rem;
        height: 0.25rem;
        background-color: $color-greyscale-00;
        border-radius: 100%;
      }
      > a {
        color: $color-greyscale-00;
      }
    }
  }
}
.section-testimonials {
  text-align: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
  @include media-breakpoint-up(lg) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  &__subtitle {
    font-size: 14/16 +rem;
    text-transform: uppercase;
    font-weight: 700;
    color: $color-greyscale-03;
    letter-spacing: 1px;
  }
  &__title {
    margin-bottom: 1rem;
    &--accent {
      @include text-underline($color-accent-yellow);
    }
  }
  &__container {
    padding: 0 1rem;
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
    max-width: map_get($container-max-widths, 'xl');
    margin: 0 auto;
  }
}
.testimonial {
  position: relative;
  width: 100%;
  margin: 3rem auto 2px auto;
  text-align: center;
  padding: 2em;
  border: 1px solid $color-greyscale-01;
  @include media-breakpoint-up(lg) {
    width: percentage(8/12);
    padding: 4rem;
  }
  &::after,
  &::before {
    content: '';
    position: absolute;
    top: -42px;
    right: 91.25px;
    width: 44.75px;
    height: 80px;
    z-index: 10;
    background-image: url("/public/img/comma.svg");
  }
  &::after {
    right: 40px;
  }
  &__text {
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: #03060c;
    line-height: (32/24);
  }
  &__person {
    &__image {
      margin-bottom: 1rem;
    }
    &__name {
      font-weight: 600;
      font-size: 1rem;
      color: $color-greyscale-07;
      line-height: 1.5;
    }
    &__position {
      font-weight: 600;
      color: $color-greyscale-03;
      line-height: 1.5;
    }
  }
}

.section-lets-talk {
  color: $color-greyscale-00;
  background-color: $color-bg-dark-level-up;
  position: relative;
  &__text {
    padding: 80/16 + rem 1rem;
    @include media-breakpoint-up(lg) {
      padding: 80/16 + rem 0;
    }
  }
  &__title {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    line-height: 1.2;
    @include media-breakpoint-up(sm) {
      font-size: 2.5rem;
    }
  }
  &__contact {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 8/16 +rem;
    line-height: (40/24);
    span {
      color: $color-accent-red;
    }
  }
  &__cta {
    margin-top: 2rem;
    i {
      margin-left: 0.5rem;
    }
  }
  &__image-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    min-height: 30rem;
    height: 160%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    @include media-breakpoint-up(lg) {
      height: 135%;
      min-height: 20rem;
    }
  }
  &__image {
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
}
.footer {
  background-color: $color-bg-dark;
  color: $color-greyscale-00;
  &__logo {
    margin-bottom: 3.75rem;
  }
  &__slogan {
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 2.5rem;
    &--accent {
      color: $color-accent-yellow;
      @include text-underline(transparentize($color-accent-yellow, 0.8))
    }
  }
  &__contact-container {
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 5.25rem;
    }
  }
  &__contact {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 8/16 +rem;
    line-height: (40/24);
    span {
      color: $color-accent-red;
    }
  }
  &__right-side {
    @include media-breakpoint-up(lg) {
      padding-left: percentage(1/12);
    }
  }
  &__left-side {
    @include media-breakpoint-up(lg) {
      border-right: 1px solid transparentize($color-greyscale-00, 0.85);
    }
  }
  &__left-side,
  &__right-side {
    padding-top: 3rem;
    padding-bottom: 1.5rem;
    @include media-breakpoint-up(lg) {
      padding-top: 7rem;
      padding-bottom: 5.5rem;
    }
  }
  &__social {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__made-with {
    text-align: center;
    font-weight: 600;
    padding: 1.5rem 1rem;
    border-top: 1px solid transparentize($color-greyscale-00, 0.85);
    i {
      color: $color-accent-yellow;
      margin: 0 0.5rem;
    }
  }
  &__social-item {
    display: inline-block;
    margin-bottom: 3rem;
    & + & {
      margin-left: 1rem;
    }
    > a {
      display: block;
      padding: 1.25rem;
      background-color: transparentize($color-greyscale-00, 0.9);
      color: $color-greyscale-00;
      line-height: 1;
      font-size: 1.5rem;
      &:hover {
        color: $color-accent-red;
      }
      i {
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
      }
    }
  }
}
.newsletter {
  margin-bottom: 4.5rem;
  &__title {
    margin-bottom: 2rem;
  }
  &__input {
    background-color: transparent;
    color: $color-greyscale-03;
    border: 1px solid $color-greyscale-07;
    padding: 14/16 +rem 20/16 +rem;
    line-height: (20/16);
    align-self: flex-start;
    width: 60%;
    font-size: 0.75rem;
    @include media-breakpoint-up(sm) {
      font-size: 1rem;
    }
    @include media-breakpoint-up(lg) {
      width: 60%;
    }
    &:focus {
      outline: 1px solid $color-accent-yellow;
    }
  }
  &__form {
    display: flex;
  }
  &__submit {
    margin-left: 0.5rem;
    color: $color-greyscale-00;
    line-height: (20/16);
    padding: 0.5rem 0.75rem;
    white-space: nowrap;
    @include media-breakpoint-up(sm) {
      padding: 0.75rem 1rem;
    }
    @include media-breakpoint-up(lg) {
      padding: 0.75rem 1.875rem;
    }
  }
  &__regulations {
    font-size: 0.75rem;
    margin-top: 1rem;
    @include media-breakpoint-up(lg) {
      width: 60%;
    }
    a {
      color: $color-accent-yellow;
      text-decoration: underline;
    }
  }
}
.last-posts {
  margin-bottom: 3rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  &_title {
    font-size: 1.5rem;
  }
  &__see-all {
    font-size: 1rem;
    border-bottom: 2px solid $color-accent-red;
    line-height: (20/16);
    font-weight: 700;
    align-self: center;
    color: $color-greyscale-00;
    transition: all 0.15s ease-out;
    &:hover {
      color: $color-greyscale-00;
      text-decoration: none;
      border-bottom: 2px solid transparent;
    }
  }
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__list-item {
    font-weight: 600;
    position: relative;
    &::after {
      content: "\f054";
      font-family: "Font Awesome 5 Pro";
      position: absolute;
      top: 50%;
      line-height: 0;
      right: 0;
      color: $color-accent-yellow;
    }
    & + & {
      border-top: 1px solid transparentize($color-greyscale-00, 0.8);
    }
    a {
      color: $color-greyscale-00;
      padding: 1.5rem 1rem 1.5rem 0;
      display: block;
      &:hover {
        color: $color-accent-yellow;
      }
    }
  }
}
