.list {
  list-style: none;
  padding: 0;
  margin: 0;
  &--check {
    > li {
      margin-bottom: 1rem;
      position: relative;
      padding-left: 2.5rem;
      &::before {
        content: "\f00c";
        font-family: "Font Awesome 5 Pro";
        font-weight: 900;
        position: absolute;
        top: 0;
        left: 0;
        width: 1.5rem;
        height: 1.5rem;
        font-size: 0.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background-color: transparentize($color-greyscale-10, 0.9);
      }
    }
    &-yellow {
      > li {
        &::before {
          color: $color-accent-yellow;
          background-color: transparentize($color-accent-yellow, 0.8);
        }
      }
    }
    &-green {
      > li {
        &::before {
          color: $color-accent-green;
          background-color: transparentize($color-accent-green, 0.9);
        }
      }
    }
    &-red {
      > li {
        &::before {
          color: $color-accent-dark-red;
          background-color: transparentize($color-accent-dark-red, 0.9);
        }
      }
    }
  }
  &--dot {
    > li {
      margin-bottom: 1.5rem;
      position: relative;
      padding-left: 1.375rem;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0.375rem;
        width: 0.5rem;
        height: 0.5rem;
        background-color: transparentize($color-greyscale-00, 0.9);
        border-radius: 100%;
      }
    }
    &-red {
      > li {
        &::before {
          background-color: $color-accent-dark-red;
        }
      }
    }
    &-green {
      > li {
        &::before {
          background-color: $color-accent-green;
        }
      }
    }
    &-yellow {
      > li {
        &::before {
          color: $color-accent-yellow;
          background-color: $color-accent-yellow;
        }
      }
    }
  }
  &--bold {
    font-weight: 600;
  }
}
