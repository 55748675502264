@font-face {
  font-family: Gilroy;
  font-weight: 700;
  src: url("/public/fonts/Gilroy-Bold.otf") format("opentype");
}

@font-face {
  font-family: Gilroy;
  font-weight: 600;
  src: url("/public/fonts/Gilroy-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: Gilroy;
  font-weight: 400;
  src: url("/public/fonts/Gilroy-Light.otf") format("opentype");
}
