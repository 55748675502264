/* Colors */
$color-bg-dark: #010f21;
$color-bg-dark-level-up: #001632;
$color-bg-light: #f8f8fe;
$color-accent-red: #fa144b;
$color-accent-dark-red: #9f261d;
$color-accent-green: #00b3ba;
$color-accent-yellow: #ffe066;
$color-greyscale-00: #fff;
$color-greyscale-005: #f2f3f4;
$color-greyscale-01: #e6e6e9;
$color-greyscale-02: #cdcdd2;
$color-greyscale-03: #b3b4bc;
$color-greyscale-07: #4f5161;
$color-greyscale-10: #000;

$theme-colors: (
 "primary": $color-accent-red,
 "dark-red": $color-accent-dark-red,
 "green": $color-accent-green,
 "yellow": $color-accent-yellow,
 "info": $color-accent-yellow
);

/* Grid */

$grid-gutter-width: 0;

$container-max-widths: (
        xl: 1170px
) !default;
